<template>
    <div>
        <b-card no-body class="mb-0 bb-lrr-0">
            <div class="m-2">
                <b-row>
                    <b-col cols="12" md="12">
                        <b-row>
                            <b-col cols="12" md="2" class="mb-md-0 mb-2">
                                <b-form-group label="Team" label-for="team">
                                    <v-select v-if="teamList && selectDetailUserTeam" :options="teamList"
                                        :reduce="(option) => option.value" v-model="selectDetailUserTeam"
                                        :clearable="false" placeholder="Select Team" class="w-100" />
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="3" class="mb-md-0 mb-2">
                                <b-form-group label="Employee" label-for="employee" v-if="discordUserList">
                                    <v-select v-model="selectDetailUser" :reduce="(option) => option.key" label="label"
                                        :options="sortedDiscordUsers" :clearable="false" placeholder="Select User">
                                        <template #option="{ image, label }">
                                            <b-avatar size="18" :src="image" :text="avatarText(label)" />
                                            <span class="ml-50 d-inline-block align-middle"> {{ label }}</span>
                                        </template>
                                        <template #selected-option="{ image, label }">
                                            <b-avatar size="18" :src="image" :text="avatarText(label)" />
                                            <span class="ml-50 d-inline-block align-middle"> {{ label }}</span>
                                        </template>
                                    </v-select>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="3" class="mb-md-0 mb-2">
                                <b-form-group label="Date Interval" label-for="dateInterval">
                                    <flat-pickr v-model="searchIntervalDate"
                                        :config="{ mode: 'range',defaultDate:[searchReportDiffMonthDate,searchReportDate]}"
                                        class="form-control" placeholder="YYYY-MM-DD | YYYY-MM-DD" />
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="2" class="mb-md-0 mb-2">
                                <b-form-group label="Display" label-for="displayType">
                                    <v-select v-model="selectDetailDisplay" :reduce="(option) => option.key"
                                        label="value" :options="displayOptions" :clearable="false" />
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="2" class="mb-md-0 mb-2">
                                <b-form-group label="Over Hours" label-for="overHours">
                                    <v-select v-model="selectDetailOverHours" :reduce="(option) => option.key"
                                        label="value" :options="overHoursOptions" :clearable="false" />
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </div>
        </b-card>
        <b-card no-body id="guruTableScroll" class="mb-0 mt-0" v-if="!isLoading && selectDetailUser">
            <div class="guru" v-if="discordUsers">
                <div class="guru_row guru_row-hours sticky"
                    :style="!selectDetailOverHours?'grid-template-columns: 150px repeat(12, 1fr);':''">
                    <div class="guru_row-first  d-flex  align-items-center">Date</div>
                    <span>08:00</span><span>09:00</span><span>10:00</span>
                    <span>11:00</span><span>12:00</span><span>13:00</span>
                    <span>14:00</span><span>15:00</span><span>16:00</span>
                    <span>17:00</span><span>18:00</span><span>19:00</span>
                    <span v-if="selectDetailOverHours">20:00</span>
                    <span v-if="selectDetailOverHours">21:00</span>
                    <span v-if="selectDetailOverHours">22:00</span>
                    <span v-if="selectDetailOverHours">23:00</span>
                </div>
                <div class="guru_row guru_row-lines"
                    :style="!selectDetailOverHours?'grid-template-columns: 150px repeat(12, 1fr);':''">
                    <span></span><span></span><span></span>
                    <span></span><span></span><span></span>
                    <span class="marker"></span><span></span><span></span>
                    <span></span><span></span><span></span><span></span>
                    <span v-if="selectDetailOverHours"></span>
                    <span v-if="selectDetailOverHours"></span>
                    <span v-if="selectDetailOverHours"></span>
                    <span v-if="selectDetailOverHours"></span>
                </div>
                <div class="guru_row" v-for="(dateitems,index) in discordUsers.dates" :key="index"
                    ref="refDiscordUsers">
                    <div class="guru_row-first">
                        <b-media vertical-align="center">
                            <b-link class="font-weight-bold d-block text-nowrap">
                                {{ dateitems.date }}
                            </b-link>
                            <small class="text-muted">Total: {{ minutes_to_hhmm(dateitems.total) }} Hours</small>
                        </b-media>
                    </div>
                    <ul class="guru_row-bars"
                        :style="!selectDetailOverHours?'grid-template-columns: repeat(43200, 1fr)!important;':''">
                        <li v-for="duration in dateitems.durations" :key="duration.id"
                            @click="getDetailInfo(dateitems.date,duration)"
                            :style="getColumnMinutes(index,duration.start,duration.end,duration.backColor)"
                            v-b-tooltip.html.hover.top="'Channel: '+duration.text+'<br/>'+getTooltipText(duration.start,duration.end)+' | '+duration.minutes+' min'">
                            {{ duration.text }} {{ duration.minutes }} min
                        </li>
                    </ul>
                </div>
            </div>
            <discord-list-calendar-detail ref="modal" :modalLoading="modalLoading" :title="modalTitle"
                :duration="durationDetail" :channelDetail="channelDetail"></discord-list-calendar-detail>
        </b-card>
        <b-card v-if="isLoading" class="mt-2">
            <div class="text-center my-2">
                <b-spinner class="d-block mx-auto mb-50"></b-spinner>
                <strong>Loading...</strong>
            </div>
        </b-card>

        <b-card v-if="!isLoading && !selectDetailUser" class="mt-2">
            <div class="text-left my-2">
                <strong>Please select employee</strong>
            </div>
        </b-card>
    </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import {avatarText} from '@core/utils/filter'
import flatPickr from 'vue-flatpickr-component'
import useDiscordApi from '@/composables/useDiscordApi'
import moment from 'moment'
import DiscordListCalendarDetail from '@/components/DiscordListCalendarDetail.vue'
import {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    VBTooltip,
    BOverlay,
    BFormGroup,
    BSpinner
} from 'bootstrap-vue'
export default {
    components: {
        vSelect,
        BFormGroup,
        BSpinner,
        BOverlay,
        BCard,
        BRow,
        BCol,
        BFormInput,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        flatPickr,
        DiscordListCalendarDetail,
    },
    directives: {
        'b-tooltip': VBTooltip,
        Ripple,
    },
    data() {
        return {
            displayType: '',
            modalTitle: null,
            durationDetail: null,
            channelDetail: null,
            modalLoading: false,
        }
    },

    setup() {
        const {
            discordUsers,
            searchIntervalDate,
            isLoading,
            displayOptions,
            overHoursOptions,
            fetchDetailView,
            selectDetailOverHours,
            selectDetailDisplay,
            fetchDiscordUserList,
            discordUserList,
            selectDetailUser,
            searchReportDate,
            searchReportDiffMonthDate,
            teamList,
            fetchTeamList,
            selectDetailUserTeam,
            firstLoad
        } = useDiscordApi()

        return {
            discordUsers,
            searchIntervalDate,
            isLoading,
            avatarText,
            displayOptions,
            overHoursOptions,
            fetchDetailView,
            selectDetailOverHours,
            selectDetailDisplay,
            fetchDiscordUserList,
            discordUserList,
            selectDetailUser,
            searchReportDate,
            searchReportDiffMonthDate,
            teamList,
            fetchTeamList,
            selectDetailUserTeam,
            firstLoad
        }
    },
    mounted() {
        this.fetchTeamList()
    },
    methods: {
        getColumnMinutes(shiftstart, start, end, bgcolor) {
            const shiftStartDate = moment(shiftstart).format('YYYY-MM-DD 08:00')
            const diffSeconds = parseInt(moment.duration(moment(end).diff(start)).asSeconds(), 0)
            const diffStartSeconds = parseInt(moment.duration(moment(start).diff(shiftStartDate)).asSeconds(), 0)
            if (diffStartSeconds < 1) {
                console.log('started range error')
            } else {
                const grid_start = diffStartSeconds // start date to grid
                const grid_end = grid_start + diffSeconds // end date to grid
                return 'grid-column:' + grid_start + '/' + grid_end + ';background:' + bgcolor
            }
        },
        getMinutesText(text, start, end) {
            return text + ': ' + parseInt(moment.duration(moment(end).diff(start)).asMinutes(), 0) + ' min'
        },
        getTooltipText(start, end) {
            return moment(start).format("HH:mm:ss") + " - " + moment(end).format("HH:mm:ss")
        },
        getDetailInfo(username, duration) {
            this.channelDetail = null
            this.modalLoading = true
            this.$store.dispatch('discordStore/fetchChannelInfo', duration.id)
                .then((response) => {
                    const {
                        data
                    } = response.data
                    this.channelDetail = data
                    this.modalLoading = false
                }).catch((errors) => {
                    this.modalLoading = false
                })
            this.modalTitle = username
            this.durationDetail = duration
            this.$root.$emit("bv::show::modal", "modal-primary");
        },
        minutes_to_hhmm(numberOfMinutes) {
            var duration = moment.duration(numberOfMinutes, 'minutes');
            var hh = (duration.years() * (365 * 24)) + (duration.months() * (30 * 24)) + (duration.days() * 24) + (duration.hours());
            var mm = duration.minutes();
            return hh + ':' + mm;
        }
    },
    created() {
        if (this.$route.params.userid) this.selectDetailUser = this.$route.params.userid
        if (!this.$route.params.userid && this.selectDetailUserTeam === null) this.selectDetailUserTeam = this.discordUsers ? this.discordUsers.team_id : 1

    },
    updated() {
        if (!this.$route.params.userid && this.selectDetailUser === null && !this.firstLoad) {
            this.selectDetailUser = this.discordUserList ? this.discordUserList[0].key : null
            if (this.selectDetailUser !== null) this.firstLoad = true
        }
    },
    computed: {
        sortedDiscordUsers: function () {
            return this.discordUserList.sort((a, b) => a.label.localeCompare(b.label));
        }
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';

.tooltip-inner {
    text-align: left;
}
</style>
